import {  Navbar,  Nav, Form, Button, Container, NavDropdown } from 'react-bootstrap';
import {  NavLink } from 'react-router-dom';



export default function MyNavbar(){
    return(
        <>

<Navbar expand="lg" className='navbar'>
      <Container>
      <Navbar.Brand href="/">
        <img className='logogo' src='images/clear1.jpg'></img>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse  className="navvv" id="basic-navbar-nav">
          <Nav
            className="me-auto my-2 my-lg-0 navni"
            style={{ maxHeight: '100px' }}
            navbarScroll
          >
            {/* <Nav.Link className='navtext' href="#action1">Why Innovated Building Group?</Nav.Link>
            <Nav.Link className='navtext'  href="#action1">Solutions</Nav.Link>
            <Nav.Link className='navtext' href="#action1">Resources</Nav.Link>*/}
            {/* <Nav.Link className='navtext'  href="#action1">Contractors Nailing IT</Nav.Link> 
            <Nav.Link className='navtext'  href="#action1">Information Page </Nav.Link>  */}
          </Nav>
          {/* <Nav.Link href="#action1" className='lognav'>Log In</Nav.Link> */}
          <Button href='https://calendly.com/r-dredhartclearskymarketing/overview-of-contracting-software' className='btnstarted'>Contact Us</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>

      </>
    )
}  